import React from "react";
import { CountryInterface } from "../../../assets/interfaces";
import Dropdown from "../../ui/dropdown";
import NigeriaFlag from "../../../assets/icons/front-pages/nigeria.svg";
import { getStyles } from "../../../assets/js/utils/functions";

interface PricingCountrySelectorProps {
  setCountry: (country: string) => void;
  country: string;
  countries: CountryInterface[];
}

const PricingCountrySelector: React.FC<PricingCountrySelectorProps> = ({ setCountry, country, countries }) => {
  const dropdownOptions = !countries
    ? []
    : countries?.map((c) => ({
        text: `${c.emoji} ${c.name}`,
        onClick: () => setCountry(c.code),
      }));

  return (
    <Dropdown items={dropdownOptions}>
      <button className="flex items-center bg-white py-2 lg:py-2.5 px-2.5 sm:px-3 lg:px-3.75 border border-grey-border border-opacity-50 rounded-30 no-outline dropdown-toggle relative">
        <div className="pr-2.5 sm:pr-3 lg:pr-3.75 border-r border-grey-border border-opacity-50">
          <figure className="h-6.25 w-6.25 sm:h-7.5 sm:w-7.5 lg:h-9 lg:w-9 rounded-full bg-grey-fields-100 overflow-hidden">
            {countryFlags[country]}
          </figure>
        </div>
        <div className="pl-2.5 sm:pl-3 lg:pl-3.75 flex items-center text-dark">
          <span className="text-1sm sm:text-base lg:text-lg font-semibold inline-block min-w-18">
            {countries?.find((c) => country === c.code)?.name ?? "Nigeria"}
          </span>
          {/* prettier-ignore */}
          <svg width="18" viewBox="0 0 15 15" fill="none" className="ml-1.25 mt-px text-dark">
            <path d="M11.2 5.11249H7.30621H3.79996C3.19996 5.11249 2.89996 5.83749 3.32496 6.26249L6.56247 9.49999C7.08122 10.0187 7.92497 10.0187 8.44372 9.49999L9.67497 8.26874L11.6812 6.26249C12.1 5.83749 11.8 5.11249 11.2 5.11249Z" fill="currentColor" />
          </svg>
        </div>
      </button>
    </Dropdown>
  );
};

const countryFlags = {
  NG:
    // prettier-ignore
    <svg height="100%" viewBox="0 0 56 40" fill="none" className="transform -translate-x-1/2 relative left-1/2">
      <rect x="0.5" y="0.5" width="55" height="39" rx="3.5" fill="white" stroke="white"/>
      <mask id="mask0_1690_8219" style={getStyles({"mask-type": "alpha"})} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="40">
        <rect x="0.5" y="0.5" width="55" height="39" rx="3.5" fill="white" stroke="white"/>
      </mask>
      <g mask="url(#mask0_1690_8219)">
        <rect x="35" width="21" height="40" fill="#189B62"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 40H21V0H0V40Z" fill="#189B62"/>
      </g>
    </svg>,
  GH:
    //prettier-ignore
    <svg height="100%" viewBox="0 0 56 40" fill="none" className="transform -translate-x-1/2 relative left-1/2">
      <rect width="56" height="40" rx="4" fill="white"/>
      <mask id="mask0_1690_8224" style={getStyles({"mask-type": "alpha"})} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="40">
        <rect width="56" height="40" rx="4" fill="white"/>
      </mask>
      <g mask="url(#mask0_1690_8224)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3333H56V0H0V13.3333Z" fill="#E71F37"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 40.0001H56V26.6667H0V40.0001Z" fill="#118B56"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 26.6666H56V13.3333H0V26.6666Z" fill="#FDD64C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28 23.2234L24.0815 26.0601L25.5685 21.4567L21.6597 18.6066L26.4972 18.5982L28 14L29.5028 18.5982L34.3404 18.6066L30.4316 21.4567L31.9186 26.0601L28 23.2234Z" fill="#262626"/>
      </g>
    </svg>,
  ZA:
    //prettier-ignore
    <svg height="100%" viewBox="0 0 15 15" fill="none" className="transform -translate-x-1/2 relative left-1/2">
      <g clip-path="url(#clip0_1450_4217)">
        <rect width="15" height="15" rx="7.5" fill="white"/>
        <path d="M13.5714 0H1.42857C0.639593 0 0 0.89543 0 2V13C0 14.1046 0.639593 15 1.42857 15H13.5714C14.3604 15 15 14.1046 15 13V2C15 0.89543 14.3604 0 13.5714 0Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10H15V15H0V10Z" fill="#1A47B8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H15V5H0V0Z" fill="#F93939"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72357 10L1.42857 15.033H0V0H1.42857L5.71429 5H15V10H5.72357Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 9L0.357143 15L0 15.033V0H0.357143L5.5 6H15V9H5.5Z" fill="#249F58"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3L4.28571 7.5L0 12V3Z" fill="#FFDA2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4L3.39286 7.5L0 11V4Z" fill="#151515"/>
      </g>
      <defs>
        <clipPath id="clip0_1450_4217">
          <rect width="15" height="15" rx="7.5" fill="white"/>
        </clipPath>
      </defs>
    </svg>,
  KE:
    //prettier-ignore
    <svg height="100%" viewBox="0 0 15 15" fill="none" className="transform -translate-x-1/2 relative left-1/2">
      <g clip-path="url(#clip0_1450_4289)">
        <rect width="15" height="15" rx="7.5" fill="#151515"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 10H18V11H-3V10Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 11H18V15H-3V11Z" fill="#0A6A30"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 5H18V10H-3V5Z" fill="#F93939"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-3 4H18V5H-3V4Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.41392 5.914C9.48592 5.914 9.91392 5.244 9.91392 4.414C9.91392 3.586 9.48592 2.914 9.41392 2.914C9.34392 2.914 8.91392 3.586 8.91392 4.414C8.91392 5.244 9.34392 5.914 9.41392 5.914ZM6.47492 5.475C6.42492 5.525 5.64492 5.355 5.06092 4.768C4.47592 4.182 4.30392 3.404 4.35492 3.354C4.40492 3.304 5.18292 3.474 5.76892 4.061C6.35492 4.647 6.52592 5.425 6.47592 5.476L6.47492 5.475ZM8.35392 10.008C8.40392 9.961 9.18392 10.121 9.76892 10.668C10.3539 11.218 10.5259 11.945 10.4759 11.992C10.4259 12.039 9.64592 11.879 9.05992 11.332C8.47492 10.782 8.30292 10.055 8.35392 10.008ZM6.47492 10.008C6.42492 9.961 5.64492 10.121 5.06092 10.668C4.47592 11.218 4.30392 11.945 4.35492 11.992C4.40492 12.039 5.18292 11.879 5.76892 11.332C6.35492 10.782 6.52592 10.055 6.47592 10.008H6.47492Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 12C7.857 12 10 9.985 10 7.5C10 5.015 7.857 3 7.5 3C7.143 3 5 5.015 5 7.5C5 9.985 7.143 12 7.5 12Z" fill="#F93939"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 9C5.57 9 6 8.328 6 7.5C6 6.672 5.57 6 5.5 6C5.43 6 5 6.672 5 7.5C5 8.328 5.43 9 5.5 9ZM9.5 9C9.57 9 10 8.328 10 7.5C10 6.672 9.57 6 9.5 6C9.43 6 9 6.672 9 7.5C9 8.328 9.43 9 9.5 9Z" fill="#151515"/>
        <path d="M8 7.5C8 6.67157 7.77614 6 7.5 6C7.22386 6 7 6.67157 7 7.5C7 8.32843 7.22386 9 7.5 9C7.77614 9 8 8.32843 8 7.5Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1450_4289">
          <rect width="15" height="15" rx="7.5" fill="white"/>
        </clipPath>
      </defs>
    </svg>,
};

export default PricingCountrySelector;
